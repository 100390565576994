<h3 *ngIf="isNew" class="mat-h3 p-4">Add new user</h3>
<h3 *ngIf="!isNew" class="mat-h3 p-4">Edit user</h3>
<mat-dialog-content>
  <div class="flex gap-2 flex-wrap">
    <mat-form-field appearance="fill">
      <mat-label>First name</mat-label>
      <input matInput [(ngModel)]="newUserData.firstName" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Last name</mat-label>
      <input matInput [(ngModel)]="newUserData.lastName" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Email</mat-label>
      <input matInput required [(ngModel)]="newUserData.email" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Password</mat-label>
      <input
        required
        matInput
        [(ngModel)]="newUserData.password"
        type="password"
      />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Level</mat-label>
      <mat-select
        [(value)]="newUserData.level"
        [disabled]="!levelChangeAvailable"
      >
        <mat-option></mat-option>
        <mat-option [value]="option.value" *ngFor="let option of levels">{{
          option.viewValue
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <app-region-select
      *ngIf="visibleForAdminOnly()"
      [regions]="regions"
      [regionsFiltered]="regionsFiltered"
      [selectedRegions]="selectedRegions"
      [handleRegionClick]="handleRegionClick.bind(this)"
    ></app-region-select>
  </div>
  <div *ngIf="!isAdmin()">
    <h4 class="mat-h4 pt-4">Projects and categories</h4>
    <app-projects-selector
      [newUserData]="newUserData"
      [visibleForAdminOnly]="visibleForAdminOnly()"
    ></app-projects-selector>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="accent" mat-flat-button [mat-dialog-close]="undefined">
    Cancel
  </button>
  <button color="primary" mat-flat-button (click)="save()" cdkFocusInitial>
    {{ addNewLabel() }}
  </button>
</mat-dialog-actions>
