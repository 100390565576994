import { Component, Input } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-project-region-selector',
  templateUrl: './project-region-selector.component.html',
  styleUrls: ['./project-region-selector.component.scss'],
})
export class ProjectRegionSelectorComponent {
  @Input()
  public regions: Record<string, { name: string; selected: boolean }[]> = {};
  @Input()
  public projectId: string = '';
  @Input()
  public handleRegionClick: (regionNames: string[], projectId: string) => void =
    (regionNames: string[], projectId: string) => {
      //
    };
  public selectedRegions: string[] = [];
  public options: string[] = [];

  constructor() {}

  public ngOnInit(): void {
    this.options = this.regions[this.projectId].map((region) => region.name);
    this.selectedRegions = this.regions[this.projectId]
      .filter((region) => region.selected)
      .map((region) => region.name);
  }

  public handleSelection(event: MatSelectChange): void {
    if ((event.value as string[]).includes('ALL')) {
      this.selectedRegions = [...event.value, ...this.options];
      this.handleRegionClick(this.options, this.projectId);
    } else {
      this.handleRegionClick(event.value, this.projectId);
    }
  }
}
