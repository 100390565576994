import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TUser, UserLevel, UserStatus } from '@shared/interfaces/TUser';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { isNullOrUndefined } from '@shared/utils/isNullOrUndefined';
import { RegionsService, TRegion } from 'src/app/services/RegionsService';
import { LoadingService } from '../../../services/LoadingService';

interface TLevel {
  value: UserLevel;
  viewValue: string;
}

export interface TNewUserData {
  user: Partial<TUser> | undefined;
  isNew: boolean;
  currentUser: boolean;
  levelChangeAvailable: boolean;
}

@Component({
  selector: 'app-new-user-dialog',
  templateUrl: './new-user-dialog.component.html',
  styleUrls: ['./new-user-dialog.component.scss'],
})
export class NewUserDialogComponent {
  public currentUser: boolean = false;
  public isNew: boolean = true;
  public levelChangeAvailable: boolean = true;
  public newUserData: Partial<TUser> = {
    categories: [],
    countries: [],
    email: ``,
    firstName: ``,
    lastName: ``,
    level: UserLevel.User,
    password: ``,
    projects: [],
    status: UserStatus.Active,
  };
  public regions: TRegion[] = [];
  public regionsFiltered: TRegion[] = [];
  public selectedRegions = new FormControl<TRegion[]>([]);
  public levels: TLevel[] = [
    {
      value: UserLevel.Admin,
      viewValue: `Admin`,
    },
    {
      value: UserLevel.User,
      viewValue: `User`,
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TNewUserData,
    private _regionsService: RegionsService,
    private _loadingService: LoadingService,
    private _dialogRef: MatDialogRef<NewUserDialogComponent>
  ) {
    this.regions = this._regionsService.getAllCountries();
    this.regionsFiltered = this._regionsService.getAllCountries();
    if (!isNullOrUndefined(data) && !isNullOrUndefined(data.user)) {
      this.newUserData = data.user;
      this.isNew = data.isNew;
      this.currentUser = data.currentUser;
      this.levelChangeAvailable = data.levelChangeAvailable;
      if ((data.user.countries?.length ?? 0) > 0) {
        const selectedCountries: TRegion[] = this.regions.filter((v) => {
          return (data.user?.countries?.indexOf(v.value) ?? -1) > -1;
        });
        this.selectedRegions.setValue(selectedCountries);
      }
    }
  }

  public isAdmin(): boolean {
    return this.newUserData.level === UserLevel.Admin;
  }

  public visibleForAdminOnly(): boolean {
    return !this.currentUser && !this.isAdmin();
  }

  public handleRegionClick(region: TRegion): void {
    this.newUserData.countries = this.selectedRegions.value?.map(
      (v) => v.value
    );
  }

  public addNewLabel(): string {
    return this.isNew ? `Add` : `Update`;
  }

  public save() {
    if (
      this.isNew &&
      (isNullOrEmpty(this.newUserData.email) ||
        isNullOrEmpty(this.newUserData.password))
    ) {
      this._loadingService.triggerErrorMessage(`Please fill required fields`);
    } else {
      this._dialogRef.close(this.newUserData);
    }
    return;
  }
}
